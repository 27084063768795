import React, { useReducer } from "react";

import { GlobalStateContext, INITIAL_STATE } from "./contexts";

const select_pictures = (pics_array, number) => {
  const _tmp_sel = JSON.parse(JSON.stringify(pics_array));
  let found = _tmp_sel.find((element) => element.number === number);
  let other = _tmp_sel.filter((element) => element.number !== number);
  found.selected = !found.selected;
  let _tmp_array = [...other, found];
  _tmp_array.sort((a, b) => {
    return parseInt(a.number) - parseInt(b.number);
  });
  return _tmp_array;
};

const reducer = (state, action) => {
  switch (action.type) {
    case "AUTH":
      return { ...state, user: action.user, token: action.token, logged: true };
    case "LOGIN":
      return { ...state, user: action.value, logged: true };
    case "LOGOUT":
      return { ...state, user: null, logged: false };
    case "START_ORDER":
      return { ...state, app_state: "order", order_id: action.value };
    case "END_ORDER":
      return { ...state, app_state: null, order_id: null, pictures: [] };
    case "ADD_PICTURE":
      return {
        ...state,
        pictures: [...state.pictures, action.value],
      };
    case "SELECT_PICTURE":
      return {
        ...state,
        pictures: select_pictures(state.pictures, action.value),
      };
    case "SENDED_PHOTO_FOR_ORDER":
      return {
        ...INITIAL_STATE,
        user: state.user,
        token: state.token,
        logged: state.logged,
        app_state: "taken",
      };
    case "NEW_ORDER":
      return {
        ...INITIAL_STATE,
        user: state.user,
        logged: state.logged,
        token: state.token,
      };
    case "ORDER404":
      return {
        ...INITIAL_STATE,
        user: state.user,
        logged: state.logged,
        token: state.token,
        app_state: "order404",
      };
    case "ORDERED_IMMUTABLE":
      return {
        ...INITIAL_STATE,
        user: state.user,
        logged: state.logged,
        token: state.token,
        app_state: "immutable",
      };
    default:
      return state;
  }
};

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalStateProvider;
