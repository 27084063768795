const conf = {
  endpoints: {
    orderById: `${process.env.REACT_APP_ORDER_API}`,
    sendPackage: `${process.env.REACT_APP_SEND_PACKAGE}`,
    packageById: `${process.env.REACT_APP_PACKAGE_API}`,
    login: `${process.env.REACT_APP_LOGIN_API}`,
  },
};

const valid_status = ["processing", "brt-italy"];
export { valid_status };
export default conf;
