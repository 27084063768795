// core
import { useEffect, useState, useContext } from "react";
import useFetch from "use-http";
// conf
import conf, { valid_status } from "conf";
// state
import { GlobalStateContext } from "store/contexts";

const Order = ({ orderId, onReady }) => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const url = conf.endpoints.orderById.replace("{ID}", orderId);
  const {
    loading,
    error,
    data = {},
  } = useFetch(url, state.token, [state.token]);
  window.$T = state.token;
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (loading) return;
    if (data?.data?.data?.status === 404) {
      setStatus(false);
      const action = {
        type: "ORDER404",
      };
      dispatch(action);
    } else {
      setStatus(true);
      const sended = data?.meta?.photo_sended ? data.meta.photo_sended : false;
      //setPhotoTaken(sended);
      const pid = data?.meta?.rel_package_id ? data.meta.rel_package_id : -1;
      const commerce_status = data?.data?.status ? data.data.status : "unknow";

      //if (commerce_status === "processing") {
      if (valid_status.indexOf(commerce_status) > -1) {
        onReady({
          status: true,
          photoTaken: sended,
          packageID: pid,
          commerce_status: commerce_status,
        });
      } else {
        const action = {
          type: "ORDERED_IMMUTABLE",
        };
        dispatch(action);
      }
    }
  }, [data, loading, status, dispatch]);
  if (error) return <div>Errors!</div>;
  if (loading) {
    return (
      <p>
        <button aria-busy="true">In attesa...</button>
      </p>
    );
  }
  if (status === false) {
    return (
      <div>
        <h2>Errore</h2>
        <p>{data.data.message}</p>
      </div>
    );
  }
  if (data.meta.photo_sended === true) return <></>;

  if (status)
    return (
      <div>
        <h1>
          <i className="fa-solid fa-box-open"></i> Verifica il contenuto del
          pacco
        </h1>
        <table role="grid">
          <thead>
            <tr>
              <th style={{ textAlign: "right", width: "3ch" }} scope="col">
                Qtà
              </th>
              <th style={{ width: "10%" }}>Immagine</th>
              <th scope="col">Articolo</th>
              <th style={{ textAlign: "right" }} scope="col">
                Prezzo
              </th>
            </tr>
          </thead>
          <tbody>
            {data.data.line_items.map((prod) => {
              return (
                <tr key={prod.id}>
                  <td style={{ textAlign: "right" }} scope="row">
                    {prod.quantity}
                  </td>
                  <td>
                    <img src={prod.image.src} alt="product" />
                  </td>
                  <td>
                    <code>{prod.sku}</code>
                    <br />
                    {prod.name}
                  </td>
                  <td style={{ textAlign: "right" }}>€ {prod.total}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
};
export default Order;
