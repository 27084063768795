import conf from "conf";
const domain = "https://elisirhome.com";

const validateCode = (obj) =>
{
  try
  {
    return obj.text.startsWith(domain);
  } catch (err)
  {
    return false;
  }
};
const getOrderbyLink = (link) =>
{
  try
  {
    let order = link
      .split("/")
      .filter((i) => i.trim() !== "")
      .pop();
    return order;
  } catch (error)
  {
    console.error(error);
    return null;
  }
};
// send all fomr to Endpoint
async function orderUploader(pics, order, note, token)
{
  let return_data = { error: 0, message: "", success: false };
  let data = new FormData();
  try
  {
    data.append("order_id", order);
    if (note === "")
    {
      note = "nothing to do...";
    }
    data.append("note", note);
    const max = Math.min(pics.length, 3);
    for (let i = 0; i < max; i++)
    {
      const pic = pics[i];
      const type = pic.data.match(/^data:(.+);base64/)?.[1];
      const ext = type.split("/").pop();
      const filename = `${order}_${pic.id}.${ext}`;
      const file = await base64ToFile(pic.data, filename);
      const field = `image${parseInt(i + 1).toString()}`;
      data.append(field, file);
    }
    let opts = { ...token };
    opts.method = "POST";
    opts.body = data;
    let response = await fetch(conf.endpoints.sendPackage, opts);
    if (response.status === 400)
    {
      const msg = await response.text();
      console.log(msg);
      // for now skipping server message
      throw new Error("Ordine già presente");
    }
    if (
      response.status !== 200 &&
      response.status !== 201 &&
      response.status !== 202
    )
      throw new Error(`HTTP response code ${response.status}`);
    let json_response = await response.json();
    if (json_response.error === 1) throw new Error(json_response.message);
    return_data.success = true;
  } catch (e)
  {
    // catch rejected Promises and Error objects
    return_data = { error: 1, message: e.message };
  }
  return return_data;
}

function getToken(login, password)
{
  return {
    credentials: 'include',
    cache: "no-cache",
    headers: {
      "Authorization": `Basic ${btoa(`${login}:${password}`)}`
    }
  }
}

async function base64ToFile(data, fileName)
{
  const res = await fetch(data);
  const blob = await res.blob();
  const type = data.match(/^data:(.+);base64/)?.[1];
  return new File([blob], fileName, { type: type });
}

export { validateCode, getOrderbyLink, orderUploader, getToken };
