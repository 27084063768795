// core
import React from "react";
import { createPortal } from "react-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// components
import Login from "comp/pages/login";
import CodeScanner from "comp/pages/code_scan";
import PictureTaker from "comp/pages/pics_taker";

import Header from "comp/common/header";

// state
import GlobalStateProvider from "store/global";

const App = () =>
{
  return (
    <>
      {createPortal(<Header />, document.getElementsByTagName("header")[0])}
      <GlobalStateProvider>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/scan" element={<CodeScanner />} />
            <Route path="/take" element={<PictureTaker />} />
          </Routes>
        </Router>
      </GlobalStateProvider>
    </>
  );
};
export default App;
