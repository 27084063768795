const Thumb = ({ number, data, selected, onClick }) => {
  const classess = selected === true ? "img_thumb sel" : "img_thumb nosel";
  return (
    <div onClick={() => onClick(number)} className={classess}>
      <img src={data} className="thumb" alt="thumb" />
      {/* <span>{number}</span> */}
    </div>
  );
};
export default Thumb;
