import { useState } from "react";
import { QrScanner } from "@yudiel/react-qr-scanner";

const Scanner = ({ opts, onResult, onError, onEnter, delay = 200 }) => {
  const [open, setOpen] = useState({
    jsScan: true,
    jsInsert: false,
  });
  const [code, setCode] = useState("");
  const onClick = (evt) => {
    evt.preventDefault();
    const id = evt.target.id;
    const tmp = JSON.parse(JSON.stringify(open));
    for (const [_id] of Object.entries(open)) {
      tmp[_id] = _id === id;
    }
    setOpen(tmp);
  };
  return (
    <>
      <details open={open.jsScan}>
        <summary onClick={onClick} id="jsScan" role="button">
          <i className="fa-solid fa-qrcode"></i> Scansione QR code
        </summary>
        <div>
          {open.jsScan && (
            <QrScanner
              constraints={opts}
              onResult={onResult}
              onError={onError}
              scanDelay={delay}
            />
          )}
        </div>
      </details>
      <details open={open.jsInsert}>
        <summary onClick={onClick} id="jsInsert" role="button">
          <i className="fa-solid fa-keyboard"></i> Inserimento manuale
        </summary>
        <div>
          <input
            type="text"
            name="code"
            placeholder="codice numerico di 6 cifre"
            value={code}
            onChange={(evt) => setCode(evt.target.value)}
          />
          {code.length > 0 && (
            <button
              onClick={(evt) => {
                evt.stopPropagation();
                onEnter(code);
              }}
            >
              Recupera <i className="fa-solid fa-arrow-right"></i>
            </button>
          )}
          {code.length === 0 && (
            <button disabled>
              Recupera <i className="fa-solid fa-arrow-right"></i>
            </button>
          )}
        </div>
      </details>
    </>
  );
};
export default Scanner;
