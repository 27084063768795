// core
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// helpers
import { getToken } from "utils/helpers";
// conf
import conf from "conf";
// state
import { GlobalStateContext } from "store/contexts";

const Login = () => {
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [state, dispatch] = useContext(GlobalStateContext);

  async function login(user, pass) {
    const token = getToken(user, pass);
    const res = await fetch(conf.endpoints.login, token);
    if (res.status === 200) {
      setError("");
      const action = { type: "AUTH", token: token, user: user };
      dispatch(action);
    } else {
      setError("wrong username or password");
    }
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    login(user, pass);
  };

  // check if logged and redirect
  useEffect(() => {
    if (state.logged) navigate("/scan");
  }, [navigate, state.logged]);

  if (state.logged)
    return (
      <>
        <div>Logged as: {state.user}</div>
        <a href="/scan">Continue</a>
      </>
    );
  else
    return (
      <div>
        <hgroup>
          <h1>
            {" "}
            <i className="fa-solid fa-key"></i> Autenticazione{" "}
          </h1>
        </hgroup>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="login"
            placeholder="Login"
            value={user}
            required
            onChange={(evt) => setUser(evt.target.value)}
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            value={pass}
            required
            onChange={(evt) => setPass(evt.target.value)}
          />
          <button type="submit" className="contrast">
            Accedi <i className="fa-solid fa-arrow-right"></i>
          </button>
        </form>
        <div>{error}</div>
      </div>
    );
};
export default Login;
