const Modal = ({ open, title, children, onClose, enableOk, btn = 1 }) => {
  if (open)
    return (
      <dialog open>
        <article>
          <header>{title}</header>
          {children}
          <footer>
            {btn > 1 && (
              <a
                href="#cancel"
                role="button"
                className="secondary"
                data-target="modal-example"
                onClick={(evt) => {
                  onClose(evt, false);
                }}
              >
                Annulla
              </a>
            )}
            <a
              // disabled={!enableOk}
              href="#confirm"
              role="button"
              data-target="modal-example"
              onClick={(evt) => {
                onClose(evt, true);
              }}
            >
              Conferma
            </a>
          </footer>
        </article>
      </dialog>
    );
  return <></>;
};
export default Modal;
