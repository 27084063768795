// core

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

// style
import "./css/main.scss";

const root = ReactDOM.createRoot(document.getElementById("app_here"));

root.render(<App />);
