// core
import { useRef, useCallback } from "react"; // import useRef

// components
import Webcam from "react-webcam";

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment",
};

const CamFullScreen = ({ onShot }) => {
  const camPtr = useRef(null);
  const handleCapture = useCallback(() => {
    const imageSrc = camPtr.current.getScreenshot();
    onShot(imageSrc);
  }, [camPtr, onShot]);

  return (
    <>
      <Webcam
        audio={false}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        className={"xcam"}
        ref={camPtr}
      />
      <div className="take-btn">
        <button onClick={handleCapture}>
          <i className="fa-regular fa-circle-dot"></i> Scatta
        </button>
      </div>
    </>
  );
};
export default CamFullScreen;
