// core
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// library components
import Scanner from "comp/common/scanner";
// state
import { GlobalStateContext } from "store/contexts";
// components
import Order from "comp/common/order";
import RemotePics from "comp/common/remotepics";
// helpers
import { validateCode, getOrderbyLink } from "utils/helpers";

const opts = {
  facingMode: "environment",
  width: {
    min: 640,
    ideal: 720,
    max: 1920,
  },
  height: {
    min: 640,
    ideal: 720,
    max: 1080,
  },
};

const CodeScanner = () => {
  const [code, setCode] = useState(null);
  const [scanning, setScanning] = useState(true);
  const [showPhotoBtn, setshowPhotoBtn] = useState(false);
  const [sended, setSended] = useState(false);
  const [state, dispatch] = useContext(GlobalStateContext);
  const [val, setVal] = useState({});
  const navigate = useNavigate();

  const onResult = (result) => {
    if (validateCode(result)) {
      setCode(getOrderbyLink(result.text));
      setScanning(false);
    } else {
      alert("invalid qr, try another");
    }
  };

  const onEnter = (code) => {
    setCode(code);
    setScanning(false);
  };

  const onError = (error) => {
    console.log(error?.message);
  };

  const handleRescan = (evt) => {
    evt.preventDefault();
    setCode(null);
    setScanning(true);
  };

  const handleADD = (evt) => {
    evt.preventDefault();
    navigate("/take");
  };

  const handleVerify = (evt) => {
    setSended(false);
    evt.preventDefault();
    const action = {
      type: "END_ORDER",
    };
    dispatch(action);
    navigate("/scan");
  };

  const handleCancel = (evt) => {
    setSended(false);
    evt.preventDefault();
    const action = {
      type: "END_ORDER",
    };
    dispatch(action);
    navigate("/");
  };

  const handleProcede = (evt) => {
    evt.preventDefault();
    const action = {
      type: "START_ORDER",
      value: code,
    };
    dispatch(action);
  };

  const onReady = (val) => {
    //alert(JSON.stringify(val));
    setVal(val);
    if (val.photoTaken === true) {
      const action = {
        type: "SENDED_PHOTO_FOR_ORDER",
      };
      dispatch(action);
    } else {
      setshowPhotoBtn(val.status);
      setSended(true);
    }
  };

  // check if logged and redirect
  useEffect(() => {
    if (!state.logged) navigate("/");
  }, [navigate, state.logged]);

  if (state.app_state === "immutable")
    return (
      <>
        <h2>
          Codice inserito: <code>{code}</code>
        </h2>
        <div>
          <div>Impossibile inviare foto per quest'ordine.</div>
        </div>
        <p>
          <button onClick={handleCancel}>
            Procedi con un nuovo ordine{" "}
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </p>
      </>
    );
  if (state.app_state === "order404")
    return (
      <>
        <h2>
          Codice inserito: <code>{code}</code>
        </h2>
        <div>
          <div>Ordine non presente.</div>
        </div>
        <p>
          <button onClick={handleCancel}>
            Procedi con un nuovo ordine{" "}
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </p>
      </>
    );
  if (state.app_state === "taken")
    return (
      <>
        <h2>
          Codice inserito: <code>{code}</code>
        </h2>
        <div>
          <div>Per quest'ordine hai già caricato le foto.</div>
          <RemotePics id={val?.packageID} />
        </div>
        <p>
          <button onClick={handleCancel}>
            Procedi con un nuovo ordine{" "}
            <i className="fa-solid fa-arrow-right"></i>
          </button>
        </p>
      </>
    );
  else if (state.app_state === "order")
    return (
      <div className="page-scan">
        <Order onReady={onReady} orderId={code} />
        <div>
          {showPhotoBtn && (
            <button onClick={handleADD}>
              Scatta le fotografie <i className="fa-solid fa-arrow-right"></i>
            </button>
          )}
          {!showPhotoBtn && (
            <button disabled>
              Scatta le fotografie <i className="fa-solid fa-arrow-right"></i>
            </button>
          )}
        </div>
        <div>
          {!showPhotoBtn && sended && (
            <div>
              <div>
                <button onClick={handleVerify}>Verifica</button>
              </div>
              <div>
                <button onClick={handleCancel}>Annulla Ordine</button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  else
    return (
      <div className="page-scan">
        <h1>
          <i className="fa-regular fa-file-lines"></i> Recupero dettagli ordine
        </h1>
        <p>
          Effettua una scansione del QR code o inserisci manualmente il numero
        </p>
        {scanning && (
          <Scanner
            constraints={opts}
            onResult={onResult}
            onError={onError}
            onEnter={onEnter}
            scanDelay={200}
          />
        )}
        {!scanning && (
          <>
            <h2>
              Codice inserito: <code>{code}</code>
            </h2>
            <p>
              <button onClick={handleProcede}>
                Procedi visualizzando i dettagli{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </p>
            <p>
              <button className="secondary outline" onClick={handleRescan}>
                <i className="fa-solid fa-arrow-left"></i> Inserisci un codice
                diverso
              </button>
            </p>
          </>
        )}
      </div>
    );
};
export default CodeScanner;
