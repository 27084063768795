// core
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// state
import { GlobalStateContext } from "store/contexts";
// components
import CamFullScreen from "comp/pages/full_screen_cam";
import { createPortal } from "react-dom";
import Thumb from "comp/common/thumb";
import Selected from "comp/common/selected";
import Modal from "comp/common/modal";
import { orderUploader } from "utils/helpers";

const PictureTaker = () => {
  const [state, dispatch] = useContext(GlobalStateContext);
  const navigate = useNavigate();
  const [showCam, setShowCam] = useState(false);
  const [open, setOpen] = useState(false);
  const [sended, setSended] = useState(false);
  const [postMessage, setPostMessage] = useState(null);
  const [note, setNote] = useState("");

  // check if logged and redirect

  useEffect(() => {
    if (!state.logged) navigate("/");
    if (state.order_id === null) navigate("/");
  }, [navigate, state.logged, state.order_id]);

  const handleAdd = (evt) => {
    evt.preventDefault();
    const fcam = document.getElementById("cam-wrapper");
    fcam.style.display = "block";
    setShowCam(true);
  };

  const handleCancel = (evt) => {
    evt.preventDefault();
    setSended(false);
    const action = {
      type: "NEW_ORDER",
    };
    dispatch(action);
    navigate("/");
  };
  const handleShot = (img) => {
    const fcam = document.getElementById("cam-wrapper");
    fcam.style.display = "none";
    setShowCam(false);
    const action = {
      type: "ADD_PICTURE",
      value: {
        number: state.pictures.length,
        data: img,
        selected: false,
        id: `pic${state.pictures.length}`,
      },
    };
    dispatch(action);
  };

  const handleRevision = (evt) => {
    evt.preventDefault();
    setOpen(true);
  };

  const selected = state.pictures.filter((item) => item.selected !== false);

  const handleThumbClick = (number) => {
    const action = {
      type: "SELECT_PICTURE",
      value: number,
    };
    dispatch(action);
  };

  const token = state.token;

  const handleModalClose = (evt, btn) => {
    evt.preventDefault();
    if (btn) {
      orderUploader(selected, state.order_id, note, token).then((res) => {
        if (res.error > 0) {
          setPostMessage(res.message);
        } else {
          setPostMessage(null);
        }
        setSended(true);
      });
    } else setSended(false);
    setOpen(false);
  };

  const handleNewOrder = (evt) => {
    evt.preventDefault();
    setSended(false);
    const action = {
      type: "NEW_ORDER",
    };
    dispatch(action);
    navigate("/");
  };

  if (sended) {
    if (postMessage === null)
      return (
        <div className="page-shot">
          <h1>
            <i className="fa-regular fa-thumbs-up"></i> Le fotografie
            dell'ordine <code>{state.order_id}</code> sono state archiviate!
          </h1>
          <p>
            <button className="full" onClick={handleNewOrder}>
              <i className="fa-solid fa-repeat"></i> Ripeti procedure per nuovo
              ordine
            </button>
          </p>
        </div>
      );

    if (postMessage !== null)
      return (
        <div className="page-shot">
          <p>Ordine {state.order_id} non inviato</p>
          <p>{postMessage}</p>
          <p>
            <button className="full" onClick={handleNewOrder}>
              <i className="fa-solid fa-repeat"></i> Ripeti procedure per nuovo
              ordine
            </button>
          </p>
        </div>
      );
  }
  return (
    <>
      <h1>
        <i className="fa-solid fa-camera-retro"></i> Fotografa il contenuto del
        pacco
      </h1>
      <ul>
        <li>Scatta più fotografie e seleziona le migliori</li>
        <li>
          è possibile inserire un massimo di 3 fotografie se il pacco contiene
          molti articoli
        </li>
        <li>
          ogni articolo deve comparire una sola volta tra le fotografie inviate
        </li>
      </ul>
      <Modal
        title={"Archivia fotografie"}
        open={open}
        onClose={handleModalClose}
        btn={2}
        enableOk={note.length > 0}
      >
        <div>{`Carico ${selected.length} foto per l'ordine ${state.order_id}`}</div>
        <hr />
        <div>
          <textarea
            rows="5"
            placeholder="aggiungi delle note in archivio relative a questo pacco"
            value={note}
            onChange={(evt) => setNote(evt.target.value)}
          ></textarea>
        </div>
      </Modal>

      {showCam &&
        createPortal(
          <CamFullScreen onShot={handleShot} />,
          document.getElementById("cam-wrapper")
        )}
      <div className="page-shot">
        <div className="cam-btn">
          <button onClick={handleAdd}>
            <i className="fa-solid fa-plus"></i> Aggiungi una fotografia
          </button>
        </div>
        <div className="thumb-container">
          {state.pictures.map((img, idx) => {
            return (
              <Thumb
                key={idx}
                number={img.number}
                data={img.data}
                selected={img.selected}
                onClick={handleThumbClick}
              />
            );
          })}
        </div>
        <div style={{ textAlign: "center" }}>
          {selected.length > 0 && <Selected data={selected} />}
          {selected.length === 0 && (
            <div>Clicca sulle fotografie che vuoi inviare</div>
          )}
        </div>
        <div className="cam-btn">
          {selected.length > 0 && (
            <button onClick={handleRevision}>
              Chiudi il pacco e archivia fotografie{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          )}
          <div style={{ "margin-bottom": "2em" }}></div>
          <button className="secondary outline" onClick={handleCancel}>
            <i className="fa-solid fa-arrow-left"></i> Annulla procedura
          </button>
        </div>
      </div>
    </>
  );
};
export default PictureTaker;
