const Selected = ({ data }) => {
  const label =
    data.length === 1 ? "fotografia selezionata" : "fotografie selezionate";
  return (
    <div>
      <span>
        {data.length} {label}
      </span>
    </div>
  );
};
export default Selected;
