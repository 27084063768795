// core
import { useEffect, useState, useContext } from "react";
// conf
import conf from "conf";
// state
import { GlobalStateContext } from "store/contexts";

const RemotePics = ({ id }) => {
  const url = conf.endpoints.packageById.replace("{ID}", id);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [state, dispatch] = useContext(GlobalStateContext);

  useEffect(() => {
    if (!id || id === null || typeof id === "undefined") return;
    setLoading(true);
    fetch(url, state.token)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
        console.error(error);
      });
  }, [url, id, state.token]);

  if (error) return <div>Errors!</div>;
  if (loading) {
    return (
      <p>
        <button aria-busy="true">In attesa...</button>
      </p>
    );
  }
  const pictures = [data.image1, data.image2, data.image3].filter(
    (pic) => pic !== null
  );
  if (!id || id === null || typeof id === "undefined") return <></>;
  return (
    <>
      <div className="thumb-container">
        {pictures.map((img, idx) => {
          return (
            <div key={idx} className="img_thumb">
              <img src={img} className="thumb" alt="thumb" />
            </div>
          );
        })}
      </div>
      <hr />
    </>
  );
};

export default RemotePics;
