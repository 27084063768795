import React from "react";

const INITIAL_STATE = {
  user: null,
  logged: false,
  pictures: [],
  app_state: null,
  order_id: null,
  token: null,
};

const GlobalStateContext = React.createContext(INITIAL_STATE);
export { INITIAL_STATE, GlobalStateContext };
